import {Container, Nav, Navbar} from 'react-bootstrap';
import {Link, NavLink} from 'react-router-dom';
import './NavigationBar.css';

/**
 * La barre de navigation.
 * @return {jsx}
 */
  function NavigationBar() {
    return (
      <nav role="navigation">
      <div id="menuToggle">
        {/* <!--
        A fake / hidden checkbox is used as click reciever,
        so you can use the :checked selector on it.
        --> */}
        <input type="checkbox" />
        
        {/* <!--
        Some spans to act as a hamburger.
        
        They are acting like a real hamburger,
        not that McDonalds stuff.
        --> */}
        <span></span>
        <span></span>
        <span></span>
        
        {/* <!--
        Too bad the menu has to be inside of the button
        but hey, it's pure CSS magic.
        --> */}
        <ul id="menu">
          <Link to='/'><li>Acceuil</li></Link>
          <Link to='/about'><li>À Propos</li></Link>
          <Link to='/skills'><li>Compétences</li></Link>
          <Link to='/projects'><li>Mes Projets</li></Link>
          <Link to='/contact'><li>Contact</li></Link>
        </ul>
      </div>
    </nav>
      );
    }

    export default NavigationBar;
