import { ReactTyped } from "react-typed";
import wax from "../../assets/images/wax.jpg";
import './Home.css';
import { Link } from "react-router-dom";
import FeaturedProjects from "../Components/FeaturedProjects";

/**
 *  La page Home.
 * @return {jsx}
 */
function Home() {
    return (
        <>
        <div class='light x1'></div>
  <div class='light x2'></div>
  <div class='light x3'></div>
  <div class='light x4'></div>
  <div class='light x5'></div>
  <div class='light x6'></div>
  <div class='light x7'></div>
  <div class='light x8'></div>
  <div class='light x9'></div>
        <div className="bg">
            {/* <img src={meImg} max-width={1300} max-height={1300}/> */}
            <div className="section-Home">
                <div className="myName"> 
                    <div className="imageMe">
                        {/* <img width={480} height={900} src={meImg} alt="Image de moi"/> */}
                    </div> 
                </div>
                <div className='text-section'>
                    <h1>Mouhammad Wagane Diouf</h1>
                    <ReactTyped
                        strings ={[
                            "Je suis Développeur Fullstack" ,
                        ]}
                        typeSpeed={150}
                        backSpeed={100}
                        loop
                        className="typed-text"
                    />
                </div>

            <h3 className="cv_btn"> 
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            <a style={{color:"aliceBlue", padding:0}} href="docs/MouhammadWagane_Diouf_CV-dev.pdf" download>Télécharger mon CV</a>
          </h3>
            </div>
        </div>

        <div className="about-part floating">
            <img src={wax}/>
            <div>
                <p><span>Hey !</span> Moi c’est Mouhammad et j’ai 22 ans. Je suis un passionné de la programmation web, application mobile et d'application de bureau. Je suis actuellement étudient en technique de l'informatique au cégep Saint-Jean-Sur-Richelieu. Si vous cherchez quelqu’un d’ambitieux et de bonne humeur, je suis votre homme !</p>
                <Link to={`/about`}>
                    <button className="btn-primaire">En savoir plus</button>
                </Link>
            </div>
            
        </div>

        <div className="featured-projects-section floating">
            <h1>Mes Projets <span className="span-subtitle-feature-project">En Vedette</span></h1>
            <FeaturedProjects />
            <button className="btn-primaire"> Voir les projets</button>
        </div>

        <div className="featured-projects-section floating">
            
        </div>
    </>
    )
}

export default Home;