import React from 'react';
import WaganeOrdi from '../../assets/images/WaganeOrdi.jpg';
import EcoleSecondaireLogo from '../../assets/images/logo-ecole-secondaire-mgr-a-m-parent.png';
import WaganeGoodVibes from '../../assets/images/WaganeGoodVibes.JPG';
import Infos from '../../assets/images/infos.jpeg';
import './About.css';

/**
 * La page À Propos.
 */
function About() {
    return(
        <>
            {/* <h1>À Propos</h1> */}

            <section className="histoire">
                <div className="wrapper">
                    <div
                        className="histoires"
                        data-aos="fade-up-right"
                        data-aos-duration="3000"
                    >
                        <div className="infosHistoires">
                            <h1>Mon Histoire</h1>
                            <p>
                                Bonjour, Moi c’est Mouhammad Wagane Diouf et j’ai 21 ans. Je me
                                décris comme étant une personne ambitieuse, sérieuse, humble et
                                déterminé. Ce que j’aime le plus c’est réfléchir et régler des
                                problèmes. C’est pourquoi j’adore la programmation, celle-ci me
                                permet d'allier réflexion, création et résolution de problème.
                            </p>
                        </div>
                        <img src={WaganeOrdi} alt="Photo_Mouhammad" />
                    </div>

                    {/* Écoles Secondaire */}
                    <div className="histoires" data-aos="fade-up-left" data-aos-duration="3000">
                        <img
                            src={EcoleSecondaireLogo}
                            alt="logo-ecole-secondaire-mgr-am-parent"
                        />
                        <div className="infosHistoires">
                            <p>
                                Au secondaire, je suis allé à l'école secondaire
                                <strong>Monseigneur-Parent.</strong> Ayant déjà un faible pour les
                                technologies et les mathématiques, je suis entré dans
                                <strong> concentration Science et Technologies.</strong> C’est
                                dans ces années où j'ai créé mon premier jeu vidéo GameMaker et
                                mes premiers photomontages.
                            </p>
                        </div>
                    </div>

                    {/* Cégep Edouard Montpetit */}
                    <div
                        className="histoires"
                        data-aos="fade-up-right"
                        data-aos-duration="3000"
                    >
                        <div className="infosHistoires">
                            <p>
                                J'ai ensuite décidé d'aller au cégep
                                <strong>Édouard-Montpetit</strong> en
                                <strong> technique d’intégration en multimédia.</strong> J'ai
                                suivi ce programme pendant <strong>2 ans</strong> , et à la fin de
                                la 2e année, j'ai pu constater que le multimédia n'était pas
                                exactement ce que je recherchais.
                            </p>
                        </div>
                        <img src={WaganeGoodVibes} alt="Photo_Mouhammad" />
                    </div>

                    {/* Cégep Saint Jean Sur Richelieu */}
                    <div className="histoires" data-aos="fade-up-left" data-aos-duration="3000">
                        <img
                            src={Infos}
                            alt="logo-ecole-secondaire-mgr-am-parent"
                        />
                        <div className="infosHistoires">
                            <p>
                                Finalement, me voici en
                                <strong>technique de l'informatique</strong> au cégep
                                <strong>Saint-Jean-Sur-Richelieu</strong>. Je suis actuellement en
                                deuxième année de ce programme et j'ai trouvé ma vocation. Je
                                souhaite grandir dans cette profession en tant que futur
                                programmeur et entrepreneur.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="En-Résumé" data-aos="fade-up" data-aos-duration="3000">
                <div className="wrapper">
                    <div className="quiSuisJe">
                        <h1>Quels sont mes atouts ?</h1>
                        <div className="cards">
                            <div className="card">
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <h2>?</h2>
                                        </div>
                                        <div className="flip-card-back">
                                            <h1>Ambitieux</h1>
                                            <p>
                                                J'ai des projets à moyens et long termes qui sont prévus
                                                pour les prochaines années à venir.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <h2>?</h2>
                                        </div>
                                        <div className="flip-card-back">
                                            <h1>Sérieux</h1>
                                            <p>
                                                Au moment de travailler sur un projet j'essaie toujours
                                                d'être le plus professionnel possible.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <h2>?</h2>
                                        </div>
                                        <div className="flip-card-back">
                                            <h1>Humble</h1>
                                            <p>Je n'aime pas vanter les projets que je réalise.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="flip-card">
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <h2>?</h2>
                                        </div>
                                        <div className="flip-card-back">
                                            <h1>Déterminé</h1>
                                            <p>
                                                Lorsque je dis que je vais faire quelque chose cela peut
                                                prendre du temps, mais je le ferais.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="Objectifs" data-aos="zoom-in-up" data-aos-duration="1000">
                <div className="wrapper">
                    <div className="contentObjectif">
                        <h1>Mes futurs objectifs</h1>
                        <p>
                            Lors de l'été 2023 j'étais en stage chez <strong>Exo</strong>,
                            l'entreprise métropolitaine de transport, en tant que 
                            <strong> technicien en informatique CSTI</strong>. Mon stage était
                            d'une durée de <strong>11 semaines</strong>. <br />
                            <br />
                            Je suis actuellement à la recherche d'un emploi en temps plein ou temps partiel.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;
