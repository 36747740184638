import { Route, Routes } from 'react-router-dom';
import './App.css';
import NavigationBar from './_misc/Components/NavigationBar';
import About from './_misc/Pages/About';
import Contact from './_misc/Pages/Contact';
import Home from './_misc/Pages/Home';
import Skills from './_misc/Pages/Skills';
import Projects from './_misc/Pages/Projects';
import ProjectDetails from './_misc/Components/ProjectDetails';
import Cursor from './_misc/Components/Cursor';
// import particules from './Utilities/Particules';

/**
 * Retourne le jsx de l'App. 
 * @return {jsx}
 */
function App() {

  // const handleInit = async (main)=>{
  //   await loadSlim(main)
  // }
  return (
    // <Router>
    <div className="App">
    {/* particles js */}
    {/* <Particles id="particles" options={particules} init={handleInit}/> */}

    <Cursor/>
  
    <NavigationBar/>

    <Routes>
      <Route index path='/' element={<Home/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/skills" element={<Skills/>}/>
      <Route path="/projects" element={<Projects/>}/>
      <Route path="/project/:id" element={<ProjectDetails/>}/>
      <Route path="/contact" element={<Contact/>}/>
    </Routes>
  
    </div>
    
    // </Router> 
  );
}

export default App;
