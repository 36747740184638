import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import data from '../../data/data.json';
import './ProjectDetails.css';

function ProjectDetails() {
    const { id } = useParams();
    const [project, setProject] = useState(null);

    useEffect(() => {
        const project = data.find(proj => proj.id === parseInt(id, 10));
        setProject(project);
    }, [id]);

    if (!project) {
        return <div>Loading...</div>;
    }

    return (
        <div className="project-details">
            <h1>{project.title}</h1>
            <img src={project.image} alt={project.title} />
            <p>{project.longDescription}</p>
            <a href={project.link} target="_blank" rel="noopener noreferrer">
                <button className='btn-primaire'>Voir Projet</button>
            </a>
        </div>
    );
}

export default ProjectDetails;
